<template>
    <div>
        <table class="layui-table">
            <tr>
                <th>发布时间</th>
                <th>标题</th>
                <th>内容</th>
                <th width="180">是否在BI大屏中显示</th>
                <th width="180">是否在首页显示</th>
                <th width="100">发布人</th>
            </tr>
            <tr v-for="(item,key) in notice_list">
                <td>{{item.create_time}}</td>
                <td>{{item.title}}</td>
                <td>{{item.content}}</td>
                <td>
                    {{wordType(item.is_show_BI)}}
                </td>
                <td>
                    {{wordType1(item.is_show_home)}}

                </td>
                <td>{{item.name}}</td>

            </tr>
        </table>
        <a-pagination :current="page" :pageSize="pagesize" @change="onChange" :total="count" v-if="count>pagesize"/>
    </div>
</template>

<script>
    export default {
        name: "index",
        data() {
            return {
                notice_list: [],
                //分页
                page: 1,
                count: 0,
                pagesize: 0,

            }
        },
        watch: {
            $route: {
                handler() {
                    this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
                    this.Myannouncement()//w获取公告分页列表
                },
                deep: true
            }
        },
        mounted() {
            this.page = Number(this.$route.query.page ? this.$route.query.page : 1)
            this.Myannouncement()//w获取公告分页列表
        },
        methods: {
            Myannouncement() {
                this.$sa0.post({
                    url: this.$api('Myannouncement'),
                    data: {
                        page:this.page
                    }
                }).then((response) => {
                    this.$sa0.response({
                        response: response.data,
                        then: (response) => {
                            this.notice_list = response.data.result.list;
                        },
                        error: (response) => {
                            layer.msg(response.message)
                        },
                    })
                })
            },
            // 类型转换
            wordType(status) {
                let type_arr = ['显示', '不显示']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(status) - 1]
                } else {
                    return status
                }
            },
            // 类型转换
            wordType1(status) {
                let type_arr = ['显示', '不显示']
                if (type_arr.length >= Number(status)) {
                    return type_arr[Number(status) - 1]
                } else {
                    return status
                }
            },
            // 分页
            onChange(page) {
                this.$router.push({
                    query: {
                        page: page,
                    }
                })
            },
        }
    }
</script>

<style scoped>

</style>
